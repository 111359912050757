import React, {useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import FAQ from "components/faqs/SingleCol.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithSteps.js";
import MainFeature3 from "components/features/ThreeColWithSideImage.js";
import tw from "twin.macro";
import ReactGA from "react-ga4";
// ---------------------------------------------------------
//
//
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import logo from "images/logo-light.svg";
import imgMalware from "images/hireus/malware.webp";
import imgFinger from "images/hireus/finger.webp";

const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const cards = [
  { imageSrc: logo, title: "Malware Analysis", description: "Reverse engineering of malware samples and full reporting of capabilities" },
  { imageSrc: logo, title: "Software Development", description: "Bespoke development of cyber security tooling"},
  { imageSrc: logo, title: "Attack Surface Analysis", description: "Full product attack surface analysis, make sure your product cannot be exploited"},
];

const malwareSteps = [
  {
    heading: "Examine",
    description: "We reverse engineer the malware"
  },
  {
    heading: "Report",
    description: "A full report is produced on the malware behaviour and IOCs"
  },
  {
    heading: "Remediate",
    description: "We provide advice on detection and remediation of the threat"
  }
];

export default () => {

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page:"/hireus"});
  });

  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink}/>

      <MainFeature3
         subheading = "Services"
         heading={"Protect your organisation"}
         description="We can lend our expertise in several areas."
         cards={cards}/>

      <MainFeature2
        subheading = "Our Expertise"
        heading={<>Malware Analysis by <span tw="text-primary-900">Professionals.</span></>}
        imageSrc={imgMalware}
        imageRounded  = {true}
        imageBorder   = {false}
        imageShadow   = {false}
        imageDecoratorBlob = {false}
        textOnLeft  = {false}
        steps={malwareSteps}

        />
      

      <MainFeature1
        subheading={<Subheading>Hire Us</Subheading>}
        heading="Tap into our skill set"
        description="Interested in malware analysis, reverse engineering, software development or attack surface analysis?  Get in touch with us for a quote."
        buttonRounded={false}
        primaryButtonUrl="/contact"
        primaryButtonText="Contact Us"
        imageSrc={imgFinger}
      />
      <Footer/>
    </AnimationRevealPage>
  );
};
