import React, {Fragment} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import VerticalFeature from "components/features/VerticalWithAlternateImageAndText.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import styled from "styled-components";
import Header, {LogoLink, PrimaryAnchorLink, PrimaryLink, NavAnchorLink, NavLink, NavLinks} from "components/headers/light.js";
import logo from "images/logo-light.svg";
import ReactGA from "react-ga4";

//
// Images for first feature set at top
//

import LockIconImage from "images/lock-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import GiftIconImage from "images/gift-icon.svg";
import MicroscopeIconImage from "images/microscope-icon.svg";
import LampIconImage from "images/lamp-icon.svg";
import ArchitechureImage from "images/threaterazer/architecture.svg";

//
// Images for second feature set
//

import ShieldCogImage from "images/threaterazer/shield-security-svgrepo-com.svg";
import DatabaseImage from "images/threaterazer/surveillance-tool-svgrepo-com.svg";
import CCTVImage from "images/threaterazer/cctv-camera-smart-cctv-securitycamera-protection-secure-insurance-svgrepo-com.svg"
import AlertCircleImage from "images/threaterazer/alert-circle-svgrepo-com.svg"
import DataProtectionImage from "images/threaterazer/data-protection-svgrepo-com.svg"
import HackerImage from "images/threaterazer/hacker-svgrepo-com.svg"
import TechShieldImage from "images/threaterazer/technology-shield-svgrepo-com.svg"
import SecureTickImage from "images/threaterazer/secure-svgrepo-com.svg"
import GatewayThingImage from "images/threaterazer/gateway-security-svgrepo-com.svg"


//additional CSS to prevent image repeat etc
const cardsImageStyle = {
  backgroundSize: "256px 256px", 
  backgroundRepeat: "no-repeat",
  color: "#000"
};

const MobileBreak = tw.br`
lg:hidden md:inline sm:inline xl:hidden 2xl:hidden
`;

const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);

const LicenseLink = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;

function linkMouseOver()
{  
    ReactGA.event({
      category: 'Documentation',
      action: 'Documentation link hover'
    });
};

const productLinks = [
  <NavLinks key={1}>
  <NavLink href="/threaterazer/license/">License</NavLink>
  <NavAnchorLink offset='-100' href="#features">Features</NavAnchorLink>
  <NavAnchorLink offset='-100' href="#faq">FAQ</NavAnchorLink>
  <PrimaryLink href="https://docs.zeroperil.com/" onMouseEnter={linkMouseOver}>
      Documentation
  </PrimaryLink>
  </NavLinks>,
];

//
// Filter can be generated here:  https://codepen.io/sosuke/pen/Pjoqqp
//

const briefFeaturesCards = [
  {
    imageSrc: ShieldIconImage,
    filter: "invert(32%) sepia(55%) saturate(4148%) hue-rotate(6deg) brightness(106%) contrast(104%)",
    title: "Protection",
    description: "Block kernel & user mode exploits, shellcode and process tampering"
  },
  {
    imageSrc: MicroscopeIconImage, 
    filter: "invert(32%) sepia(55%) saturate(4148%) hue-rotate(6deg) brightness(106%) contrast(104%)",
    title: "Detection", 
    description: "Detect exploits and other malicious activity, log to the Windows Event Log"
  },
  { 
    imageSrc: LockIconImage,
    filter: "invert(32%) sepia(55%) saturate(4148%) hue-rotate(6deg) brightness(106%) contrast(104%)",
    title: "Hardening",
    description: "Blacklist applications & drivers.  Block child process execution for untrusted processes"
  },
  { 
    imageSrc: FastIconImage,
    filter: "invert(32%) sepia(55%) saturate(4148%) hue-rotate(6deg) brightness(106%) contrast(104%)",
    title: "Fast",
    description: "Fast immediate response to suspicious or malicious actions, avoid event spam and alert fatigue."
  },
  { 
    imageSrc: LampIconImage,
    filter: "invert(32%) sepia(55%) saturate(4148%) hue-rotate(6deg) brightness(106%) contrast(104%)",
    title: "On-Premise",
    description: "No data leaves your network, ThreatErazer runs entirely on the endpoint."
  },
  { 
    imageSrc: GiftIconImage,
    filter: "invert(32%) sepia(55%) saturate(4148%) hue-rotate(6deg) brightness(106%) contrast(104%)",
    title: "Free",
    description: "Free for non-commercial and non-government use."
  },
];


const detailFeaturesCards = [
  {
    imageSrc:
      `url('${ArchitechureImage}')`,
    imageStyle: {
      backgroundSize: "400px 512px", 
      backgroundRepeat: "no-repeat",
      color: "#000"
    },
    subtitle: "Adapt For Your Environment",
    title: "Fully Configurable",
    description:
    <>
      <ul>
        <li>&#x25BA; Simple JSON config file</li>
        <li>&#x25BA; Enable/Disable/Audit individual security checks</li>
        <li>&#x25BA; Audit mode can trial-run settings without risk</li>
      </ul>
    </>,
    url: "https://docs.zeroperil.com/versions/v1/configuration/"
  },
  {
    imageSrc:
      `url('${HackerImage}')`,
    imageStyle: cardsImageStyle,
    imageClass: "svg-color-to-zp",
    subtitle: "Detect And Block Exploits",
    title: "Kernel Exploit Protection",
    description:
        <Fragment>
        <ul>
          <li>&#x25BA; Detect Kernel exploits and block them before they can complete</li>
          <li>&#x25BA; Block 0-day bugs in Kernel drivers from being exploited</li>
          <li>&#x25BA; Prevent escalation of privilege</li>
        </ul>
        </Fragment>
     ,
     url: "https://docs.zeroperil.com/v1/securitycheck/#c170f7ef1c61e544413b2745a0f1efbc"
  },
  {
    imageSrc:
      `url('${ShieldCogImage}')`,
    imageStyle: cardsImageStyle,
    imageClass: "svg-color-to-zp",
    subtitle: "Counter An Initial Foothold",
    title: "User Mode Shellcode Detection",
    description:
        <Fragment>
        <ul>
          <li>&#x25BA; Detect shellcode running in user mode processes</li>
          <li>&#x25BA; Detect and block process tampering, unhooking, injection</li>
          <li>&#x25BA; Detect and block process herpaderping, doppelgang and ghost attacks</li>
          <li>&#x25BA; Detect anomalous process execution flow</li>
        </ul>
        </Fragment>
     ,
     url: "https://docs.zeroperil.com/versions/v1/securitycheck/#1ab9eda64a04812a54f83097f7e3db76"
  },
  {
    imageSrc:
      `url('${GatewayThingImage}')`,
    imageStyle: cardsImageStyle,
    imageClass: "svg-color-to-zp",
    subtitle: "Detect Malicious Behavior",
    title: "Process Event Logging",
    description:
        <Fragment>
        <ul>
          <li>&#x25BA; Optionally log process create events</li>
          <li>&#x25BA; Build a full process tree using parent process information</li>
          <li>&#x25BA; Detect process parent spoofing</li>
          <li>&#x25BA; Detect thread injection</li>
          <li>&#x25BA; Detect command line tampering</li>
        </ul>
        </Fragment>
     ,
     url: "https://docs.zeroperil.com/versions/v1/options/#1c20b56b4c6a9bb99a971f84b4b90b6c"
  },
  {
    imageSrc:
      `url('${CCTVImage}')`,
    imageStyle: cardsImageStyle,
    imageClass: "svg-color-to-zp",
    subtitle: "Detect Lateral Movement",
    title: "SMB Protocol Logging",
    description:
        <Fragment>
        <ul>
          <li>&#x25BA; Optionally Log SMB1, SMB2 and RPC connection requests</li>
          <li>&#x25BA; Detect use of protocols commonly used in lateral movement</li>
        </ul>
        </Fragment>
     ,
     url: "https://docs.zeroperil.com/versions/v1/options/#0beb38ab342033d1949ce121284767ea"
  },
  {
    imageSrc:
      `url('${DataProtectionImage}')`,
    imageStyle: cardsImageStyle,
    imageClass: "svg-color-to-zp",
    subtitle: "Detect Anomalous Traffic",
    title: "SSL Connection Logging",
    description:
        <Fragment>
        <ul>
          <li>&#x25BA; Optionally Log SSL connection requests</li>
          <li>&#x25BA; Optionally Log DNS requests</li>
          <li>&#x25BA; SSL connection information includes JA3 signatures</li>
        </ul>
        </Fragment>
     ,
     url: "https://docs.zeroperil.com/versions/v1/options/#0e4cc6f17ac56358e96c00b61431b03d"
  }, 
  {
    imageSrc:
      `url('${SecureTickImage}')`,
    imageStyle: cardsImageStyle,
    imageClass: "svg-color-to-zp",
    subtitle: "Harden Your Environment",
    title: "Application, DLL & Driver Blocking",
    description:
        <Fragment>
        <ul>
          <li>&#x25BA; Block applications and abused binaries</li>
          <li>&#x25BA; Block at the DLL level</li>
          <li>&#x25BA; Prevent malicious driver loading</li>
        </ul>
        </Fragment>
     ,
     url: "https://docs.zeroperil.com/versions/v1/configuration/#cc43720b8222dc5b0088f5cc173cb85a"
  },
  {
    imageSrc:
      `url('${DatabaseImage}')`,
    imageStyle: cardsImageStyle,
    imageClass: "svg-color-to-zp",
    subtitle: "Keep Your Data Private",
    title: "Completely On-Premise",
    description:
        <Fragment>
        <ul>
          <li>&#x25BA; No data leaves your network</li>
          <li>&#x25BA; Endpoint agent does not require internet access</li>
          <li>&#x25BA; All detection technology runs directly on the endpoint</li>
          <li>&#x25BA; Ready to use with your own SIEM solution</li>
        </ul>
        </Fragment>
     ,
     url: "https://docs.zeroperil.com/versions/v1/eventlog/"
  }, 
];


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <Fragment>
    <AnimationRevealPage>
    <Header logoLink={logoLink} links={productLinks} />
    <Features
        style={{top: "0%", position: "absolute"}}
        subheading={null}
        heading={
          <div className="blog-heading">
            <span style={{color: '#ff4c00' }}>THREAT</span><MobileBreak/><span style={{color: '#000000' }}>ERAZER</span>
          </div>
        }
        description={
          <div className="blog-heading">
            <span>LITE</span>
          </div>
        }
        cards={briefFeaturesCards}
      />
      <div id="features"/>
      <VerticalFeature
        cards={detailFeaturesCards}
        heading="Features"
        description={" "}
      />
      <div id="faq"/>
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        description={null}
        heading={
          <>
            <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is a non-commercial license ?",
            answer:
            <div>
              The term non-commercial, as used in the context of ThreatErazer Lite licensing, means personal use, academic research or other activities that are not undertaken for financial gain, advantage, or other reward and that are not intended to produce works, services, or data for commercial use. However, you should note that whether a particular use of ThreatErazer Lite is non-commercial or commercial depends on the use of the software, not the user.
              <br />
              <br />
              <br />
              <Subheading>Examples of non-commercial use include:</Subheading>
              <br />
              <br />
              <ul>
                <li>&#x25BA; Research conducted within a non-profit institution, without any intent on seeking any commercial advantage or financial gain. Activities within a non-profit institution that are funded by commercial sponsors or public/private sponsorships usually require a commercial license.</li>
                <li>&#x25BA; Use of the software within an academic setting for course study, research, training, and instructional purposes. This includes universities in which tuition fees are charged.</li>
                <li>&#x25BA; Preparation of material for academic papers, conferences, and books are considered to be non-commercial activities. You should note that the publication of results obtained with ThreatErazer Lite should include an appropriate citation.</li>
                <li>&#x25BA; Activities that indirectly generate revenue — such as clips posted on YouTube or Vimeo — are considered to be non-commercial activities. However, the creation of content that a viewer must pay to access is not.</li>
              </ul>
              <br />
              <br />
              <Subheading>Examples of commercial use include:</Subheading>
              <br />
              <br />
              <ul>
                  <li>&#x25BA; Use of the software within a commercial enterprise, whether for research purposes, product development, manufacturing, or inspection, if those activities are directly related to regular work activities or to generate revenue, profit, or any other advantage.</li>
                  <li>&#x25BA; Use of ThreatErazer Lite to generate or develop educational materials or resources which will be sold in exchange for a fee.</li>
                  <li>&#x25BA; Use by non-profit organizations to support activities that are intended toward securing a commercial advantage or the generation of revenue, monetary compensation, or other advantage requires a commercial license.</li>
                  <li>&#x25BA; Use of ThreatErazer Lite (or any related materials or resources) within a non-academic textbook, journal, or other publication, whether the material is distributed for a fee or not, falls under commercial use.</li>
              </ul>
              <br />
              <br />
              For more information please refer to the ThreatErazer Lite non-commercial license agreement 
              <br />
            </div>
          },
          {
            question: "What platforms are supported ?",
            answer:
              "The software supports Windows 10 and above. Note that this product is NOT currently designed to run on Windows Server editions."
          },
          {
            question: "Does the product require internet access ?",
            answer:
              "No, ThreatErazer works completely offline all processing takes place on the endpoint and we don't send any data out of your network.  For name resolution purposes in SSL/SMB/RPC network information event log entries, a DNS request may be made by the software."
          },
          {
            question: "How do I get technical support ?",
            answer:
              "Except as may be provided or in a separate written agreement between You and ZEROPERIL LTD, if any, ZEROPERIL LTD is under no obligation to support, maintain, or update the Software."
          },
          {
            question: "What is the pricing for a commercial license ?",
            answer:
              "Please contact us to discuss pricing options"
          },
        ]}
      />
      <div id="download"/>
      <GetStarted/>
      <Footer/>
    </AnimationRevealPage>
    </Fragment>
  );
}

