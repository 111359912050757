import React, {Fragment} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsVideoWithCenteredContent";
import Footer from "components/footers/MiniCenteredFooter.js";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const MobileBreak = tw.br`
lg:hidden md:inline sm:inline xl:hidden 2xl:hidden
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <Fragment>
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} >
        <Heading><div className="blog-heading"><span style={{color: '#ff4c00' }}>THREAT</span><MobileBreak/><span style={{color: '#FFFFFF' }}>ERAZER</span><MobileBreak/><br/>Lite</div><br/>FREE endpoint <span style={{color: '#ff4c00' }}>hardening</span> tool</Heading>
        <Link to="/threaterazer"><PrimaryAction>Get ThreatErazer</PrimaryAction></Link>
      </Hero>
      <Footer />
    </AnimationRevealPage>
    </Fragment>
  );
}
