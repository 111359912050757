import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import {Link, Img, PrimaryLink, HeadingRow, Heading, CreationDate, logoLink, navLinks, } from "pages/posts/BlogCommon.js"

export default ({}) => {
  useEffect(()=>
  {
    ReactGA.send({ hitType: "pageview", page:"/blog/helloworld/"});
  });
  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading className="blog-heading">HELLO WORLD!</Heading>
          </HeadingRow>
          <CreationDate>November 16, 2020</CreationDate>
          <br/>
          <div>
          Welcome to ZeroPeril Ltd.
          </div>
          <br/>
          <div>
          We are two individuals who are extremely passionate about technology and information security, joining forces and combining years of hands-on experience in order to turn our innovative ideas into reality and bring exciting new technologies into the industry.
          </div>
          <br/>
          <div>
          Currently we spend most of our time in R&amp;D for our upcoming releases and are very excited to share more information with you in the near future.  While that part of our efforts is ongoing, we are dedicating some time into developing a simple website where we can start sharing some research ideas and other interesting findings whenever our busy schedule permits.
          </div>
          <br/>
          <div>
          Keep calm and stay tuned!
          </div>
          <br/>
          <div>
          Kyriakos & Tom
          </div>
          <br/><br/>
          <PrimaryLink href="/blog">
            Blog Index
          </PrimaryLink>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

