import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import {Link, Img, PrimaryLink, HeadingRow, Heading, ImgCaption, CreationDate, logoLink, navLinks, } from "pages/posts/BlogCommon.js"

import imageOne from "images/blog/freshclam-openssl.webp"
import imageTwo from "images/blog/sfc-before.webp"
import imageThree from "images/blog/sfc-freshclam-pwn.webp"
import imageFour from "images/blog/sfc-pwn.webp"
import imageFive from "images/blog/cover-1.webp"


export default ({}) => {
  useEffect(()=>
  {
    ReactGA.send({ hitType: "pageview", page:"/blog/cve-2021-1280/"});
  });
 
  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading className="blog-heading">CVE-2021-1280</Heading>
          </HeadingRow>
          <Subheading>Local privilege escalation in Cisco Immunet and Cisco AMP</Subheading>
          <CreationDate>November 16, 2020</CreationDate>
          <br/>
          <div>
          ZeroPeril recently took a look at two Cisco security products for Windows, Cisco Immunet and Cisco AMP. We discovered a local privilege escalation that affects both products.
          </div>
          <br/>
          <div>
          One particular component common to both products is SFC.exe which is a system service, this service periodically executes a child process Freshclam.exe with SYSTEM permissions. Freshclam.exe is a part of ClamAV which is integrated into Cisco AMP and Cisco Immunet, and is used for updating ClamAV signature files in the product.
          </div>
          <br/>
          <div>
          An investigation using ProcMon from Sysinternals, showed that both SFC.exe and Freshclam.exe attempted to read a non-existent file from a path on the user’s C drive:
          </div>
          <br/>
          <center>
            <Img src={imageOne} alt="freshclam-openssl.webp"></Img>
            <ImgCaption>freshclam.exe attempting to read C:\SSL\openssl.cnf</ImgCaption>
          </center>
          <br/>
          <center>
            <Img src={imageTwo} alt="sfc-before.webp"></Img>
            <ImgCaption>sfc.exe attempting to read C:\SSL\openssl.cnf</ImgCaption>
          </center>
          <br/>
          <div>
          On Windows, any standard non-privileged user can create arbitrary directories in the C: drive if they do not already exist, so we decided to create this directory containing a blank file called openssl.cnf. Another run of ProcMon showed that both processes will indeed read the file if it exists:
          </div>
          <br/>
          <center>
            <Img src={imageThree} alt="sfc-freshclam-pwn.webp"></Img>
            <ImgCaption>sfc.exe and freshclam.exe read openssl.cnf if it exists</ImgCaption>
          </center>
          <br/>
          <div>
          We investigated ways to exploit this by consulting the openssl.cnf documentation and immediately our eyes were drawn to the section about engine configuration, specifically, the example that is given for loading a dynamic link library:
          </div>
          <br/>
          <br/>
          <div className="code-box">
            <code>
            [engines]<br/>
            foo = foo_engine<br/>
            <br/>
            [foo_engine]<br/>
            dynamic_path = /some/path/fooengine.so<br/>
            some_ctrl = some_value<br/>
            default_algorithms = ALL<br/>
            other_ctrl = EMPTY<br/>
            </code>
          </div>
          <br/>
          <br/>
          <div>
          Next, we decided to put together a simple DLL that would spawn a command prompt in the interactive session, by duplicating the primary token from a WinLogon.exe process, which runs as SYSTEM in the same session as the interactive user. We placed this dll on the following path: C:\ampwn\ampwn.dll
          </div>
          <br/>

          <div>
          Using a specially crafted openssl.conf file, as shown below:
          </div>
          <br/>
          <br/>
          <div className="code-box">
            <code>

            openssl_conf = openssl_init<br/>
            [openssl_init]<br/>
            engines = engine_section<br/>
            [engine_section]<br/>
            ampwn = ampwn_section<br/>
            [ampwn_section]<br/>
            engine_id = ampwn<br/>
            dynamic_path = c:\ampwn\ampwn.dll<br/>
            init = 0<br/>
            </code>
          </div>
          <br/>
          <br/>
          <div>
          We were then able to coerce both sfc.exe and freshclam.exe to load our dll:
          </div>
          <br/>
          <center>
            <Img src={imageFour} alt="sfc-pwn.webp"></Img>
            <ImgCaption>sfc.exe loading our dll ampwn.dll</ImgCaption>
          </center>
          <br/>
          <div>
          By abusing the openssl.cnf file in this way we then obtained an interactive command prompt process running with full system privileges:
          </div>
          <br/>
          <center>
            <Img src={imageFive} alt="cover-1.webp"></Img>
            <ImgCaption>Command prompt running as SYSTEM, launched by sfc.exe system service</ImgCaption>
          </center>
          <br/>

          <Subheading>Conclusion</Subheading>
          <br/>
          <div>
          If you are using the OpenSSL libraries in your project, its worth checking if there is a default SSL config path that could be writable by a low privilege user, as this could be used by a SYSTEM service or high privileged process as described in this post.
          </div>
          <br/>

          <Subheading>Timeline</Subheading>
          <br/>
          <div>
          <ul>
          <li>Initial discovery: 10/12/2020</li>
          <li>Reported: 10/12/2020</li>
          <li>Vendor replied: 11/12/2020</li>
          <li>Issue acknowledged: 14/12/2020</li>
          <li>Disclosed: 20/01/2021</li>
          </ul>
          </div>
          <br/>
          <div>
          The Cisco disclosure of this issue can be found here:
          </div>
          <br/>
          <div>
          <Link href="https://tools.cisco.com/security/center/content/CiscoSecurityAdvisory/cisco-sa-amp-imm-dll-5PAZ3hRV">https://tools.cisco.com/security/center/content/CiscoSecurityAdvisory/cisco-sa-amp-imm-dll-5PAZ3hRV"</Link>
          </div>
          <br/>
          <br/>
          <PrimaryLink href="/blog">
            Blog Index
          </PrimaryLink>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

