import React, {Fragment} from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header, {LogoLink, PrimaryLink } from "components/headers/light.js";
import logo from "images/logo-light.svg";
import ReactGA from "react-ga4";
import { ReactComponent as SvgDecoratorBlob3 } from "images/zeroperil.svg";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import styled from "styled-components";
import { triggerBase64Download } from '../react-base64-downloader';



const HeadingContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;


const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;


const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-2xl`;
const SubHeading1 = tw(SubheadingBase)`text-2xl`;
const SubHeading2 = tw(SubheadingBase)`text-lg`;

const VerticalSpacer = tw.div`mt-10 w-full`

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute left-0 top-0 opacity-[.09]`}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 opacity-10 text-primary-900`}
`;

const MobileBreak = tw.br`
lg:hidden md:inline sm:inline xl:hidden 2xl:hidden
`;

const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);

//
// Filter can be generated here:  https://codepen.io/sosuke/pen/Pjoqqp
//

export default () => {
  //const getFile = () =>
  //{
  //  fetch(BinaryBlob).then((response) => response.text())
  //  .then((data) => {
  //      triggerBase64Download(data, "ThreatErazer Lite-0.1.0-win64");
  //  });
//
  //}

  return (
    <Fragment>
    <AnimationRevealPage>
    <Header logoLink={logoLink}/>
      <DecoratorBlob transform='translate(-400 -128) rotate(-6) scale(2.5)'/>
      <DecoratorBlob2 transform='translate(0 -256) rotate(12) scale(1.2)'/>
      <div>
        <HeadingContainer>
          <Heading>
              <div className="blog-heading">
                  <span style={{color: '#ff4c00' }}>THREAT</span><MobileBreak/><span style={{color: '#000000' }}>ERAZER</span>
              </div>
          </Heading>
          <center>
          <Description>
                <div className="blog-heading">
                    <span>LITE</span>
                </div>
          </Description>
          </center>
        </HeadingContainer>
        <VerticalSpacer />
        <SubHeading1>ThreatErazer Releases</SubHeading1>
        <VerticalSpacer />
        <SubHeading2>ThreatErazer Lite 0.1.0</SubHeading2>
        <VerticalSpacer />
        <SubHeading2>Hashes</SubHeading2>
        <VerticalSpacer />
        <div>
           <ul>
            <li>&#x25BA; Name: ThreatErazer Lite-0.1.0-win64.exe</li>
            <li>&#x25BA; Size: 5479088 bytes (5350 KiB)</li>
            <li>&#x25BA; SHA256: 58CCB7ECB56C3D5599219883A2503D5533C2944528174487C85E4A8ED6957231</li>
           </ul>
        </div>
        <VerticalSpacer />
        <SubHeading2>Release Notes</SubHeading2>
        <VerticalSpacer />
        <div>
           <ul>
             <li>&#x25BA; Initial public release</li>
             <li>&#x25BA; <span style={{color: "red", fontWeight: "bold"}}>YOU ARE ADVISED TO CREATE A FULL SYSTEM BACKUP BEFORE INSTALLING.</span></li>
           </ul>
        </div>
        <VerticalSpacer />
        <SubHeading2>Non-commercial Licence Key ThreatErazer Lite 0.1.0</SubHeading2>
        <VerticalSpacer />
        <div style={{width: "30%", wordBreak: "break-word"}}>
0wAAAEAAAACDAAAAYhu18SRFwiZO0Hh2+3Sob2QP9mg0GVs81IQgtfGfPJevexqzdNW6Sh1GvPFI1FPRzeB/gFl3rUvL8xqNwUiHoR5SVSzOAAAAAq5Ob24tY29tbWVyY2lhbKC7RnJlZSBGb3IgTm9uIENvbW1lcmNpYWwgVXNlpEZyZWWQxBQAAAAAAAAAAAAAAAAAAAAAAAAAAM8AAAAAdpHoAM8AAAAAY8KJkM8AAAAAAAAAAM8AAAAAAAAAAM8AAAAAAAAAAc8AAAAAAAAAAA==
        </div>
        <VerticalSpacer />
        <VerticalSpacer />
        <PrimaryLink href="/download/0.1.0/ThreatErazer Lite-0.1.0-win64.exe" target="_blank" download>Download</PrimaryLink>
        <VerticalSpacer />
        
        <VerticalSpacer />
      { /* End of animated section */ }
      </div> 
      <Footer/>
    </AnimationRevealPage>
    </Fragment>
  );
}

