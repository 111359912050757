import React, { useEffect, useRef, useState} from "react";
import ReactGA from "react-ga4";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
// ---------------------------------------------------------
//
//
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import logo from "images/logo-light.svg";
import loader from "images/loader.gif";
const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);


const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  const frameRef = useRef(null);
  const spinnerRef = useRef(null);
  const [ spinnerVisible, setSpinnerVisible ] = useState(true);
  function hideSpinner()
  {
    setSpinnerVisible(false);
  }
  useEffect(()=>
  {
    ReactGA.send({ hitType: "pageview", page:"/shop"});
  });
  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} />
      <div style={{display : spinnerVisible ? 'visible' : 'none'}} ref={spinnerRef}>
        <div>
             <center><img height="64px" src={loader} alt="Loading..."/></center>
        </div>
      </div>
      <iframe ref={frameRef} title="shop" src="https://wp.zeroperil.com/shop/" width="100%" height="2200px" scrolling="no" frameBorder="0" onLoad={hideSpinner} onError={hideSpinner}  style={{minHeight:"2200px"}}/>
      <Footer />
    </AnimationRevealPage>
  );
};
