import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import {Link, Img, PrimaryLink, HeadingRow, Heading, ImgCaption, CreationDate, logoLink, navLinks, } from "pages/posts/BlogCommon.js"

import imageOne from "images/blog/sfc-before-unrar.webp"
import imageTwo from "images/blog/freshclam-unrar-post2.webp"
import imageThree from "images/blog/cover-1.webp"

export default ({}) => {
  useEffect(()=>
  {
    ReactGA.send({ hitType: "pageview", page:"/blog/cve-2021-1386/"});
  });
  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading className="blog-heading">CVE-2021-1386</Heading>
          </HeadingRow>
          <Subheading>Another local privilege escalation vulnerability in Cisco AMP, Immunet &amp; ClamAV</Subheading>
          <CreationDate>April 7, 2021</CreationDate>
          <br/>
          <div>
          If you have been following our blog you will know that Zeroperil recently found a local privilege escalation vulnerability affecting Cisco AMP and Immunet; CVE-2021-1280. At the same time we also found a secondary vulnerability affecting Cisco AMP and Immunet, resulting in local privilege escalation which is detailed in this post.
          </div>
          <br/>
          <div>
          Disclosure was coordinated with Cisco in order to allow the issue to be fixed before being made public.
          </div>
          <br/>
          <div>
          CVE-2021-1386 is very similar to <Link href="/blog/cve-2021-1280/">CVE-2021-1280</Link> in that it again involves SFC.exe which is a system service loading a DLL, and also involves freshclam.exe.
          </div>
          <br/>

          <div>
          Using the ProcMon tool from <Link href="https://docs.microsoft.com/en-us/sysinternals/" target="_blank">Microsoft SysInternals</Link> we were able to see that the SFC.exe service attempts to load the DLL libclamunrar_iface.dll.9.0.4 and searches for this DLL in each directory contained within the system PATH environment variable.
          sfc searching for unrar library
          </div>
          <br/>

          <div>
          Using ProcMon we could also see that freshclam.exe (which is periodically executed by the system service SFC.exe) also attempts to load libclamunrar_iface.dll.9.0.4 from each directory contained within the system PATH environment variable.
          </div>
          <br/>
            <center>
              <Img src={imageOne} alt="sfc-before-unrar.webp"></Img>
              <ImgCaption>SFC.exe searches for libclamunrar_iface.dll.9.0.4</ImgCaption>
            </center>
          <br/>
          <div>
          In order to exploit this vulnerability, an attacker would need to be able to write a DLL into one of the system PATH locations. This isn’t as difficult as you may think, during our time on Red Team engagements we have often seen misconfigured directory permissions on directories that are in the PATH environment.
          </div>
          <br/>

          <div>
          For example Python 2.7 by default has a world writable installation directory, and the installer often adds the installation directory to the PATH environment variable.
          </div>
          <br/>

          <div>
          In the image below, the vulnerability is being exploited using a default installation of <Link src="https://www.python.org/download/releases/2.7/">Python 2.7</Link>, signified by the SUCCESS output in ProcMon as the malicious dll is successfully loaded from the C:\Python27\ directory.
          freshclam searches for libclamunrar_iface.dll.9.0.4.
          </div>
          <br/>
          <center>
              <Img src={imageTwo} alt="freshclam-unrar-post2.webp"></Img>
              <ImgCaption>freshclam.exe searches for libclamunrar_iface.dll.9.0.4</ImgCaption>
          </center>
          <br/>
          <div>
          Using this technique it was possible for a standard low privileged user to obtain SYSTEM privileges:
          </div>
          <br/>
          <center>
              <Img src={imageThree} alt="cover-1.webp"></Img>
              <ImgCaption>cmd.exe with SYSTEM privilege obtained by exploiting the vulnerability</ImgCaption>
          </center>
          <br/>
          <Subheading>Conclusion</Subheading>
          <br/>
          <div>
          In order to mitigate vulnerabilities and attacks like this, it is essential that you ensure the system PATH environment variable does not contain writable paths, something that every enterprise should check after installing new software.
          </div>
          <br/>

          <div>
          Software vendors should run tools such as ProcMon as part of their quality assurance pipeline, in order to detect this kind of vulnerability.
          </div>
          <br/>
          <Subheading>Affected Products</Subheading>
          <br/>
          <div>
          <ul>
              <li>Cisco AMP for Endpoints Windows Connector - <Link src="https://www.cisco.com" target="_blank">https://www.cisco.com</Link></li>
              <li>ClamAV for Windows - <Link src="https://www.clamav.net/downloads" target="_blank">https://www.clamav.net/downloads</Link></li>
              <li>Immunet <Link src="https://www.immunet.com/" target="_blank">https://www.immunet.com/</Link></li>
          </ul>
          </div>
          <br/>
          <Subheading>Timeline</Subheading>
          <br/>

          <div>
          <ul>
            <li>Initial discovery: 10/12/2020</li>
            <li>Reported: 10/12/2020</li>
            <li>Vendor replied: 11/12/2020</li>
            <li>Issue acknowledged: 14/12/2020</li>
            <li>Disclosed: 07/04/2021</li>
          </ul>
          </div>
          <br/>

          <div>
          The Cisco disclosure of this issue can be found at <Link href="https://tools.cisco.com/security/center/content/CiscoSecurityAdvisory/cisco-sa-amp-imm-dll-tu79hvkO">https://tools.cisco.com/security/center/content/CiscoSecurityAdvisory/cisco-sa-amp-imm-dll-tu79hvkO</Link>
          </div>
          <br/>
          <br/>
          <br/>
          <PrimaryLink href="/blog">
            Blog Index
          </PrimaryLink>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

