import React, { useEffect, Fragment, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import ReactGA from "react-ga4";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from "react-router-dom";

//
// Blog posts
// ---------------------------------------------------------

import BlogPostHookDump from "./posts/BlogPostHookDump.js"
import BlogPostHelloWorld from "./posts/BlogPostHelloWorld.js";
import BlogPostCVE20211280 from "./posts/BlogPostCVE20211280.js";
import BlogPostDisclosurePolicy from "./posts/BlogPostDisclosurePolicy.js";
import BlogPostCVE20211386 from "./posts/BlogPostCVE20211386.js";
import BlogPostCVE202126333 from "./posts/BlogPostCVE202126333.js";
import BlogPostTraining from "./posts/BlogPostTraining.js";

//
// Image
//
//----------------------------------------------------------

import imageTraining from "images/blog/training.webp";
import imageAMD from "images/blog/Memory_BP_SingleShoot.webp";
import imageAmp1 from "images/blog/cover-small.webp";
import imageAmp2 from "images/blog/ampmain-small.webp";
import imageDisclosure from "images/blog/bugs.webp";
import imageHookDump from "images/blog/hookdump5.webp";
import imageHelloWorld from "images/blog/helloworld-small.webp";

// ---------------------------------------------------------
//
//
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import logo from "images/logo-light.svg";
const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg h-full`;
//const Image = styled.div`
//  ${props => css`background-image: url("${props.imageSrc}");`}
//  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
//`;

const Image = styled.div`
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;

const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none h-full`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Blog Index",
  posts = [
    {
      imageSrc: `url('${imageTraining}')`,
      category: "Training Course",
      date: "December 22, 2021",
      title: "Training course announcement",
      description:
        "We are proud to announce the first in a series of highly technical training courses that we have been working on for some time.",
      url: "malware-unpacking-training/",
      featured: true
    },
    {
      imageSrc: `url('${imageAMD}')`,
      category: "CVE",
      date: "September 15, 2021",
      title: "CVE-2021-26333 - AMD Chipset Driver information disclosure vulnerability",
      description:
        "",
      url: "cve-2021-26333/",
      featured: false
    },
    {
      imageSrc: `url('${imageAmp2}')`,
      category: "CVE",
      date: "April 7, 2021",
      title: "CVE-2021-1386 - Privilege escalation in Cisco AMP, Immunet & ClamAV",
      description:
        "",
      url: "cve-2021-1386/",
      featured: false
    },
    {
      imageSrc: `url('${imageDisclosure}')`,
      category: "CVE",
      date: "March 31, 2021",
      title: "Update to our coordinated disclosure policy",
      description:
        "",
      url: "coordinated-disclosure-policy/",
      featured: false
    },  
    {
      imageSrc: `url('${imageHookDump}')`,       
      category: "Tool",
      date: "March 30, 2021",
      title: "HookDump - EDR hook detection with low false positives",
      description:
        "",
      url: "hookdump/",
      featured: false
    },
    {
      imageSrc: `url('${imageAmp1}')`,
      category: "CVE",
      date: "January 20, 2021",
      title: "CVE-2021-1280 - Privilege escalation in Cisco Immunet and Cisco AMP",
      description:
        "",
      url: "cve-2021-1280/",
      featured: false
    },
    {
      imageSrc: `url('${imageHelloWorld}')`,
      category: "General",
      date: "November 16, 2020",
      title: "Hello World!!",
      description:
        "",
      url: "helloworld/",
      featured: false
    },
  ]
}) => {
  const [visible, setVisible] = useState(7);
  const { path, url } = useRouteMatch();
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page:"/blog"});
  });
  return (
     <Router>
      <Switch>
        <Route exact path={path} >
          <AnimationRevealPage>
            <Header logoLink={logoLink}/>
            <Container>
              <ContentWithPaddingXl>
                <HeadingRow>
                  <Heading>{headingText}</Heading>
                </HeadingRow>
                <Posts>
                  {posts.slice(0, visible).map((post, index) => (
                    <PostContainer key={index} featured={post.featured}>
                      <Post className="group" as="a" href={`${path}${post.url}`}>
                        <Image style={{backgroundImage: `${post.imageSrc}` }} />
                        <Info>
                          <Category>{post.category}</Category>
                          <CreationDate>{post.date}</CreationDate>
                          <Title>{post.title}</Title>
                          {post.featured && post.description && <Description>{post.description}</Description>}
                        </Info>
                      </Post>
                    </PostContainer>
                  ))}
                </Posts>
                {visible < posts.length && (
                  <ButtonContainer>
                    <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
                  </ButtonContainer>
                )}
              </ContentWithPaddingXl>
            </Container>
            <Footer />
          </AnimationRevealPage>          
        </Route>
        {
            // New blog posts here :
        }
        <Route path={`${path}hookdump/`}>
          <BlogPostHookDump />
        </Route>
        <Route path={`${path}helloworld/`}>
          <BlogPostHelloWorld />
        </Route>
        <Route path={`${path}cve-2021-1280/`}>
          <BlogPostCVE20211280/>
        </Route> 
        <Route path={`${path}cve-2021-1386/`}>
          <BlogPostCVE20211386/>
        </Route> 
        <Route path={`${path}cve-2021-26333/`}>
          <BlogPostCVE202126333/>
        </Route> 
        <Route path={`${path}coordinated-disclosure-policy/`}>
          <BlogPostDisclosurePolicy/>
        </Route>
        <Route path={`${path}malware-unpacking-training/`}>
          <BlogPostTraining/>
        </Route>
      </Switch>
    </Router>
  );
};

