import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { ShopLink, Link, Img, PrimaryLink, HeadingRow, Heading, CreationDate, logoLink, navLinks, } from "pages/posts/BlogCommon.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { faSquareMinus } from "@fortawesome/free-solid-svg-icons";

import imageOne from "images/blog/Memory_BP_SingleShoot.webp"

const Collapsible = (props) => {
  const [open, setToggleOpen] = useState(true);
  const toggle = () => {
    setToggleOpen(!open);
  };

  return (
    <div style={{backgroundColor : "gainsboro"}}>
      <button onClick={toggle}>{!open && <Subheading><FontAwesomeIcon icon={faSquarePlus}/>&nbsp;{props.title}</Subheading>}{open && <Subheading><FontAwesomeIcon icon={faSquareMinus}/>&nbsp;{props.title}</Subheading>}</button>
      {open && <div>{props.children}</div>}
    </div>
  );
};

export default () => {
  useEffect(()=>
  {
    ReactGA.send({ hitType: "pageview", page:"/blog/malware-unpacking-training/"});
  });

  function shopLinkMouseEnter()
  {
      ReactGA.event({
        category: 'Shop',
        action: 'Shop link hover'
      });
  };

  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading className="blog-heading"> <span style={{color: '#ff4c00' }}>Advanced</span> <span style={{color: '#000000' }}>Malware Unpacking</span></Heading>
          </HeadingRow>
          <Subheading>Deep dive into executable packers and malware unpacking</Subheading>
          <CreationDate>December 22, 2021</CreationDate>
          <br/>
          <center>
            <Img style={{height : "256px" }} src={imageOne} alt="Memory_BP_SingleShoot.webp"></Img>
          </center>
          <br/>
          <div>
          <center>
            <ShopLink onMouseEnter={shopLinkMouseEnter} href="https://zeroperil.com/shop/">Register Now!</ShopLink>
          </center>
          </div>
          <br/>
          <div>
          We are proud to announce the first in a series of highly technical training courses that we have been working on for some time.
          </div>
          <br/>
          <div>
          What started as a hobby with reverse engineering software protection, later became a large part of our professional careers in deciphering and debugging heavily obfuscated malware.
          </div>
          <br/>
          <div>
          Zeroperil has combined the most interesting aspects from reverse engineering software protection and malware into this fast paced and intense course.
          </div>
          <br/>
          <div>
          The course aims to teach students how to deal with a large variety of executable packers, crypters and modern malware loaders.
          </div>
          <br/>
          <div>
          Attendees will learn advanced tactics, techniques and procedures in order to be able to retrieve the original malware samples from within multiple layers of obfuscation. As a result, static analysis will be easier and indicators of compromise can be extracted. Unpacked malware binaries will be fully working, allowing for dynamic analysis.
          </div>
          <br/>
          <div>
          Students will gain the confidence to approach unknown executable packers and crypters by learning and applying principles that we will be teaching throughout the course.
          </div>
          <br/>
          <div>
          Techniques for automated sample unpacking by taking advantage of the x86dbg/x64dbg scripting engine will be covered. By the end of the course students will be able to write their own powerful debugger automation scripts.
          </div>
          <br/>
          <Collapsible title="Day 1">
          <br/>
          <ul>
          <li style={{marginLeft: "16px", listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>x86/x64 architecture refresher</li>
          <li style={{marginLeft: "16px", listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Microsoft Windows ABI</li>
          <li style={{marginLeft: "16px", listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>PE file format refresher</li>
          <li style={{marginLeft: "16px", listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Debugging techniques</li>
          <li style={{marginLeft: "16px", listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Anti-debugging techniques</li>
          <li style={{marginLeft: "16px", listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>A tour of x86dbg</li>
          <li style={{marginLeft: "16px", listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Introduction to executable packers</li>
          </ul>
          </Collapsible>
          <br/>
          <Collapsible title="Day 2">
          <br/>
          <ul>
          <li style={{marginLeft: "16px",listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Deep dive into manual unpacking of executable packers</li>
          <li style={{marginLeft: "16px",listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Automated unpacking and x86dbg scripting</li>
          </ul>
          </Collapsible>
          <br/>
          <Collapsible title="Day 3">
          <br/>
          <ul>
          <li style={{marginLeft: "16px",listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Deep dive into reverse engineering malware loaders</li>
          <li style={{marginLeft: "16px",listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Q&amp;A session and workshop</li>
          </ul>
          </Collapsible>

          <br/>
          <Subheading>Delivery</Subheading>    
          <br/>
          <div>
          The training course is fully remote and sessions will be conducted over Microsoft Teams. Each student will be presented with a copy of the training materials.
          </div>
          <br/>
          <Subheading>Prerequisites</Subheading>    
          <br/>
          <div>
          Although this course does go through the necessary introductory and background theory of several technical aspects, the following set of skills is desirable prior attending:
          <br/>
          <br/>
          <ul>
          <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Basic usage of user-mode debuggers (Olly, x64/x32Dbg)</li>
          <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Basic understanding of x86/x64 assembly language</li>
          <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Basic knowledge of programming concepts such as pointers, loops, functions etc…</li>
          <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Experience with handling malware safely (i.e. Virtual Machines and network segregation)</li>
          </ul>
          </div>
          <br/>
          <Subheading>Technical requirements</Subheading>    
          <br/>
          <div>
          Although this course does go through the necessary introductory and background theory of several technical aspects, the following set of skills is desirable prior attending:
          <br/>
          <br/>
          <ul>
          <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Computer/laptop able to handle a VM with a minimum of 4 dedicated CPU cores, and 4 GB of RAM</li>
          <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Good internet connectivity</li>
          <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>A virtualisation software that is able to take runtime snapshots of the guest OS</li>
          <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>A virtualisation software that is able to run modern Windows OS (10, 8.1, 7)</li>
          </ul>
          </div>
          <br/>
          <br/>
          <div>
          <center>
            <ShopLink onMouseEnter={shopLinkMouseEnter} href="https://zeroperil.com/shop/">Register Now!</ShopLink>
          </center>

          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <PrimaryLink href="/blog">
            Blog Index
          </PrimaryLink>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

