import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";

import logo from "images/logo-light.svg";

export const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);

export const Link = tw.a`underline text-primary-900`
export const LinkSpan = tw.span`underline text-primary-900`
export const Img = tw.img``;
export const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
export const ShopLink = tw(PrimaryLinkBase)`rounded-full bg-orange-500 text-white`
export const HeadingRow = tw.div`flex`;
export const Heading = tw(SectionHeading)`text-gray-900`;
export const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
export const ImgCaption = tw.div`pt-2 text-gray-600 italic font-semibold text-xs`;

export const navLinks = [
    <NavLinks key={1}>
    <NavLink href="/#">About</NavLink>
    <NavLink href="/blog">Blog</NavLink>
    <NavLink href="/#">Contact Us</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#">
        Hire Us
      </PrimaryLink>
    </NavLinks>
  ];