import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { LinkSpan, Link, Img, PrimaryLink, HeadingRow, Heading, ImgCaption, CreationDate, logoLink, navLinks, } from "pages/posts/BlogCommon.js"
import { Link as RouterLink } from "react-router-dom"

import pdfOne from "images/blog/AMD_PSP_Vulnerability_Report.pdf"


export default ({}) => {
  useEffect(()=>
  {
    ReactGA.send({ hitType: "pageview", page:"/blog/cve-2021-26333/"});
  });
  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading className="blog-heading">CVE-2021-26333</Heading>
          </HeadingRow>
          <Subheading>AMD Chipset Driver Information Disclosure Vulnerability</Subheading>
          <CreationDate>September 15, 2021</CreationDate>
          <br/>
          <div>
          We recently discovered a critical information disclosure vulnerability that affected the AMD Platform Security Processor (PSP) chipset driver for multiple CPU architectures.
          </div>
          <br/>
          <div>
          The vulnerability allowed non-privileged users to read uninitialised physical memory pages, where the original data was either moved or paged out.
          </div>
          <br/>
          <div>
          The complete report can be downloaded <RouterLink to={pdfOne} target="_blank" download><LinkSpan>here</LinkSpan></RouterLink>.
          </div>
          <br/>
          <div>
          The official advisory by AMD can be found <Link href="https://www.amd.com/en/corporate/product-security/bulletin/amd-sb-1009" target="_blank">here</Link>.
          </div>
          <br/>
          <div>
          Please note that the list of affected products may not be complete, as we were able to verify this vulnerability in two different systems using Ryzen 2000 and 3000 series CPUs, which are not currently listed in the AMD advisory.
          </div>
          <br/>
          <div>
          <span style={{fontWeight : "bold"}}>Latest Ryzen 5000 series CPUs</span> chipset driver for PSP was not tested, but it is recommended that you update to the latest version.
          </div>
          <br/>
          <div>
          At the time of writing the latest chipset drivers for the affected CPU architectures, can be found <Link href="https://www.amd.com/en/support/chipsets/amd-socket-am4/x570" target="_blank">here.</Link>  
          </div>
          <br/>
          <Subheading>Update 17/09/2021</Subheading> 
          <br/>
          <div>
          AMD revised their advisory with additional CPU architectures that were affected, including Ryzen 5000 series and Threadripper CPUs.
          </div>
          <br/>
          <Subheading>Timeline</Subheading>
          <br/>
          <div>
          <ul>
            <li>Vendor Contacted: 08/04/2021</li>
            <li>Vendor Replied: 09/04/2021</li>
            <li>Vendor Acknowledged: 12/5/2021</li>
            <li>Public Disclosure: 14/09/2021</li>
          </ul>
          </div>
          <br/>

          <br/>
          <br/>
          <br/>
          <PrimaryLink href="/blog">
            Blog Index
          </PrimaryLink>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

