import React, {Fragment} from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header, {LogoLink, PrimaryAnchorLink, PrimaryLink, NavAnchorLink, NavLink, NavLinks} from "components/headers/light.js";
import logo from "images/logo-light.svg";
import ReactGA from "react-ga4";
import { ReactComponent as SvgDecoratorBlob3 } from "images/zeroperil.svg";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import styled from "styled-components";

const HeadingContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;


const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-2xl`;
const SubHeading1 = tw(SubheadingBase)`text-2xl`;
const SubHeading2 = tw(SubheadingBase)`text-xl`;

const VerticalSpacer = tw.div`mt-10 w-full`

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute left-0 top-0 opacity-[.09]`}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 opacity-10 text-primary-900`}
`;

const MobileBreak = tw.br`
lg:hidden md:inline sm:inline xl:hidden 2xl:hidden
`;

const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);


//
// Filter can be generated here:  https://codepen.io/sosuke/pen/Pjoqqp
//

export default () => {

  return (
    <Fragment>
    <AnimationRevealPage>
    <Header logoLink={logoLink}/>
      <DecoratorBlob transform='translate(-400 -128) rotate(-6) scale(2.5)'/>
      <DecoratorBlob2 transform='translate(0 -256) rotate(12) scale(1.2)'/>
      <div>
        <HeadingContainer>
          <Heading>
              <div className="blog-heading">
                  <span style={{color: '#ff4c00' }}>THREAT</span><MobileBreak/><span style={{color: '#000000' }}>ERAZER</span>
              </div>
          </Heading>
          <center>
          <Description>
                <div className="blog-heading">
                    <span>LITE</span>
                </div>
          </Description>
          </center>
        </HeadingContainer>
        <VerticalSpacer />
   
        <SubHeading1>Non-commercial License Agreement</SubHeading1>
        <VerticalSpacer />
        <div>
          The terms of this License form a binding agreement between individual users or organizations ("Licensee", "you" or "your") and ZEROPERIL LTD. ("ZEROPERIL", "Licensor", "we", "our" or "us") regarding your non-commercial use of ThreatErazer Lite ("Software", "Program", or "Application"). ThreatErazer Lite is an endpoint protection application designed to detect and prevent various classes of Windows Kernel exploits, user mode exploits and malware behaviour.  ThreatErazer Lite includes its installers, drivers, services, and associated documentation.
        </div>
        <VerticalSpacer />
        <div>
          This Agreement covers your permitted download, installation, and use of ZEROPERIL LTD licensed materials and the ThreatErazer Lite software. BY "INSTALLING" THE SOFTWARE YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, UNDERSTAND THEM, AND AGREE TO BE LEGALLY BOUND BY THEM. If you do not agree with the terms of this Agreement, you may not install the Software or use ZEROPERIL LTD licensed materials.
        </div>
        <VerticalSpacer />

        <SubHeading2>Scope</SubHeading2>
        <br/>
        <div>
          <ul>
            <li>&#x25BA; This Agreement describes the licensing of the Software provided to you on a non-commercial basis.</li>
            <li>&#x25BA; If you plan to use ThreatErazer Lite software on a commercial basis or to protect business assets, you must contact ZEROPERIL LTD and separately purchase a commercial-use license.</li>
          </ul>
       </div>
       <VerticalSpacer />

       <SubHeading2>General</SubHeading2>
       <VerticalSpacer />
       <div>
          A non-exclusive, non-transferable, license is granted to you free-of-charge to install and use one (1) copy of the Software for academic and non-profit purposes. You may not network the Software or otherwise install it or use it on more than one computer at a time. Use of the Software under this license is restricted to non-commercial purposes only. 
       </div>
       <VerticalSpacer />

       <SubHeading2>Permitted Use</SubHeading2>
       <VerticalSpacer />
       <div>
         You agree to use the Software solely for non-commercial purposes and shall not distribute or transfer the Software to any person or third parties, without prior written permission from ZEROPERIL LTD. 
         <VerticalSpacer />
         The term "non-commercial", as used in this License, means personal use, academic or other scholarly research use is permitted which: (a) is not undertaken for profit; or (b) is not intended to produce works, services, or data for commercial use; or (c) is neither conducted, nor funded, by a person or an entity engaged in the commercial use, application or exploitation of works similar to the Software. 
       </div>
       <VerticalSpacer />

       <SubHeading2>Restrictions</SubHeading2>
       <VerticalSpacer />
       <div>
           You are specifically prohibited from:
           <VerticalSpacer />
           <ul>
           <li>&#x25BA; Transferring, assigning, sublicensing, or renting the Software or using it or any sub-component of it, in any type of environment where the functionality of the Software is provided to a third party.</li>
           <li>&#x25BA; Causing or permitting the reverse engineering, decompiling, disassembly, or translation of the Software to create a functional equivalent.</li>
           <li>&#x25BA; The Software is licensed to you as a single product. Its component parts may not be separated for use on more than one computer.</li>
           <li>&#x25BA; Evaluating or using, or facilitating the evaluation of use, of the Software for the purpose of competing with ZEROPERIL LTD.</li>
           </ul>
        </div>
        <VerticalSpacer />

        <SubHeading2>Proprietary Rights</SubHeading2>
        <VerticalSpacer />
        <div>
          The Software is licensed, not sold. Your license confers no title or ownership of the Software.
          <VerticalSpacer />
          The Software, user interface, designs, know-how, and other technologies provided by ZEROPERIL LTD as part of the Software are the proprietary property of ZEROPERIL LTD and its licensors, and all right, title, and interest in and to such items, including all associated intellectual property rights, remain only with ZEROPERIL LTD and its licensors. The Software is protected by applicable copyright and other intellectual property laws. You may not remove any product identification, copyright, trademark, or other notice from the Software.
          <VerticalSpacer />
          If you receive a request to furnish all or any portion of the Software to a third party, the Licensee will not fulfil such a request, but will refer the third party of ZEROPERIL LTD so that the third party's use of the Software will be subject to the terms and conditions of this License. 
        </div>
        <VerticalSpacer />


        <SubHeading2>Acknowledgement</SubHeading2>
        <VerticalSpacer />
        <div>
          You agree that any publication of results obtained with the Software will be acknowledged by an appropriate citation that "Zeroperil ThreatErazer Lite" was used.
        </div>
        <VerticalSpacer />

        <SubHeading2>No Maintenance Or Support</SubHeading2>
        <VerticalSpacer />
        <div>
          The Software and all related materials and resources are licensed to you without any offer or promise to; (a) provide maintenance or support for the Software; (b) notify you of bug fixes, patches, or upgrades to the Software (if any). If in its sole discretion, ZEROPERIL LTD makes a bug fix, patch, or upgrade available to you and does not separately enter into a written license agreement with you relating to such a bug fix, patch, or upgrade, then it shall be deemed incorporated into the Software and subject to this agreement.
        </div>
        <VerticalSpacer />

        <SubHeading2>Disclaimer Of Warranties</SubHeading2>
        <VerticalSpacer />
        <div>
          THE SOFTWARE IS SUPPLIED "AS IS" WITHOUT WARRANTY OF ANY KIND. ZEROPERIL LTD AND ITS LICENSORS; (a) DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT; (b) DO NOT ASSUME ANY LEGAL LIABILITY OR RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS, OR USEFULNESS OF THE SOFTWARE; (c) DO NOT REPRESENT THAT USE OF THE SOFTWARE WOULD NOT INFRINGE PRIVATELY OWNED RIGHTS; (d) DO NOT WARRANT THAT THE SOFTWARE WILL FUNCTION UNINTERRUPTED, THAT IT IS ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED. 
        </div>
        <VerticalSpacer />
        
        <SubHeading2>Limitation Of Liability</SubHeading2>
        <VerticalSpacer />
        <div>
          IN NO EVENT WILL ZEROPERIL LTD OR ITS LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES OF ANY KIND OR NATURE, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS OR LOSS OF DATA, FOR ANY REASON WHATSOEVER, WHETHER SUCH LIABILITY IS ASSERTED ON THE BASIS OF CONTRACT, TORT (INCLUDING NEGLIGENCE OR STRICT LIABILITY), OR OTHERWISE, EVEN IF ZEROPERIL LTD HAS BEEN WARNED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES. IN NO EVENT SHALL ZEROPERIL LTD's LIABILITY FOR DAMAGES ARISING FROM OR IN CONNECTION WITH THIS AGREEMENT EXCEED THE AMOUNT PAID BY YOU FOR THE SOFTWARE. 
        </div>
        <VerticalSpacer />
        
        <SubHeading2>Termination</SubHeading2>
        <VerticalSpacer />
        <div>       
          Either party may terminate this agreement immediately upon material breach of the other party. Upon termination of this agreement, you must discontinue all use of the Software, de-install, and destroy the original and all copies, full or partial, of the Software, including any modifications or derivative works and documentation. Upon ZEROPERIL LTD's request, users will provide written certification of such compliance. 
        </div>
        <VerticalSpacer />
        
        <SubHeading2>Enforceability</SubHeading2>
        <VerticalSpacer />
        <div>       
            If any provision of this Agreement is held to be invalid or unenforceable under any applicable statute or rule of law, it is to that extent deemed omitted from the License. Such an omission will not affect the validity of the remaining provisions of the License, which will remain in full force and effect.
            <VerticalSpacer />
            This agreement may only be amended, altered, or modified by an instrument in writing, specifying such amendment, alteration, or modification, executed by both parties. 
        </div> 
        <VerticalSpacer />
        
        <SubHeading2>Delivery Of The Software</SubHeading2>
        <VerticalSpacer />
        <div> 
            The Software will be delivered to the Licensee by means of an Internet download. 
            <VerticalSpacer />
            Installation of the Software is the responsibility of the Licensee. 
        </div>
        <VerticalSpacer />

      { /* End of animated section */ }
      </div> 
      <Footer/>
    </AnimationRevealPage>
    </Fragment>
  );
}

