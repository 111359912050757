import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { LinkSpan, Link, Img, PrimaryLink, HeadingRow, Heading, ImgCaption, CreationDate, logoLink, navLinks, } from "pages/posts/BlogCommon.js"
import { Link as RouterLink } from "react-router-dom"

import pdfOne from "images/blog/AMD_PSP_Vulnerability_Report.pdf"


export default ({}) => {
  useEffect(()=>
  {
    ReactGA.send({ hitType: "pageview", page:"/blog/coordinated-disclosure-policy/"});
  });
  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading className="blog-heading">Coordinated Disclosure Policy</Heading>
          </HeadingRow>
          <Subheading>We have updated our coordinated disclosure policy document.</Subheading>
          <CreationDate>March 31, 2021</CreationDate>
          <br/>
          <Subheading>TL; DR</Subheading>
          <br/>
          <div>
          Public disclosure will generally occur within a 90 days time frame of first notifying the vendor of an issue.
          </div>
          <br/>
          <div>
          There are a few caveats to this, such as situations where the vendor fails to respond.
          </div>
          <br/>
          <div>
          Please read the full disclosure policy document for more information:
          </div>
          <br/>
          <div>
          <RouterLink to={pdfOne} target="_blank" download><LinkSpan>Download Updated Policy</LinkSpan></RouterLink>
          </div>
          <br/>
          <br/>
          <br/>
          <PrimaryLink href="/blog">
            Blog Index
          </PrimaryLink>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

