import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { useEffect, useState, Fragment } from "react";
import CookieNotice from 'react-cookienotice'
import 'react-cookienotice/dist/index.css'
import Cookies from 'js-cookie';
import ReactGA from "react-ga4";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
//import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

import LandingPage from "pages/LandingPage.js";


/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
import BlogIndex from "pages/BlogIndex.js";
import ThreatErazerLandingPage from "pages/ThreatErazerProductLandingPage.js"
import ThreatErazerLicencePage from "pages/ThreatErazerLicencePage.js"
import ThreatErazerDownloadPage from "pages/ThreatErazerDownloadPage.js"
import HireUsPage from "pages/HireUs.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
import ShopPage from "pages/Shop.js";
import ProductIndex  from "pages/ProductIndex.js"

// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import( /* webpackPreload: true */ "fonts/recharge bd.woff")

/*
<Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/thank-you">
          <ThankYouPage />
        </Route>
*/        

ReactGA.initialize('G-31ZQ53FM0M');
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});

export default function App() {
  const [cookiesEnabled, setCookiesEnabled] = useState(false);
  function onCookieAccept()
  {
    //alert("cookies enabled");
  }
 
  function onCookieDecline()
  {
    //alert("cookies enabled");
  }
 
  useEffect(() => {
    const cookie = Cookies.get("i_love_zeroperil_cookies");
    if( cookie === "true" )
    {
      onCookieAccept();
    }
    else if( cookie === "false" )
    {
      onCookieDecline();
    }
  });

  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  return (
    <div>
     <div  style={{zIndex:"10000"}} >
     <CookieNotice
      cookieName="i_love_zeroperil_cookies"
      cookieExpiration={90}
      titleLabel="Cookie Information"
      descriptionLabel="This site uses strictly necessary cookies to allow the site to work.  This type of cookie does not collect any personally identifiable information about you and does not track your browsing habits."
      hideDeclineButton={true}
      acceptButtonLabel="I understand"
      onDeclineButtonClick={onCookieDecline}
      onAcceptButtonClick={onCookieAccept}
     />
     </div>
     <Router>
      <Switch>
       {
            // The old wordpress website used URLs like this
            // zeroperil.co.uk/hookdump
            // for the blog posts.  So here we also add a URL that resolves to 
            // the same thing, so that old links work...
            //
            // New blog posts can come under /blog/postname
            // so we only do this for old ones below
        }
        <Route path={`/training-course-announcement/`}>
          <Redirect to="/blog/malware-unpacking-training/" />
        </Route>
        <Route path={`/cve-2021-26333/`}>
          <Redirect to="/blog/cve-2021-26333/" />
        </Route>
        <Route path={`/wp-content/uploads/2021/09/AMD_PSP_Vulnerability_Report.pdf`}>
          <Redirect to={"/blog/cve-2021-26333/"} />
        </Route>
        <Route path={`/cisco-amp-and-immunet-local-privilege-escalation-vulnerability-cve-2021-1386/`}>
          <Redirect to={"/blog/cve-2021-26333/"} />
        </Route>
        <Route path={`/coordinated-disclosure-policy/`}>
          <Redirect to="/blog/coordinated-disclosure-policy/" />
        </Route>
        <Route path={`/cisco-lpe-cve-2021-1280/`}>
          <Redirect to="/blog/cve-2021-1280/" />
        </Route>
        <Route path={`/hookdump/`}>
          <Redirect to="/blog/hookdump/" />
        </Route>
        <Route path={`/hello-world-2/`}>
          <Redirect to="/blog/helloworld/" />
        </Route>
        <Route path={`/tag/zeroperil`}>
          <Redirect to="/blog" />
        </Route>
        <Route path={`/tag/edr`}>
          <Redirect to="/blog/hookdump/" />
        </Route>
        <Route path={`/threaterazer/licence/`}>
          <Redirect to="/threaterazer/license/" />
        </Route>
        {
            // New URLS
        }

        <Route path="/blog/">
          <BlogIndex />
        </Route>
        <Route path="/about">
          <AboutUsPage />
        </Route>
        <Route path="/contact">
          <ContactUsPage />
        </Route>
        <Route path={`/shop/`}>
            <ShopPage />
        </Route>
        <Route path={`/threaterazer/license/`}>
            <ThreatErazerLicencePage />
        </Route>
        <Route path={`/threaterazer/download/`}>
            <ThreatErazerDownloadPage />
        </Route>
        <Route path={`/threaterazer/`}>
            <ThreatErazerLandingPage />
        </Route>
        <Route path={`/products/`}>
            <ProductIndex />
        </Route>
        <Route path="/">
            <LandingPage />
        </Route>
      </Switch>
    </Router>
    </div>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
