import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import {Link, Img, PrimaryLink, HeadingRow, Heading, CreationDate, logoLink, navLinks, } from "pages/posts/BlogCommon.js"

import imageOne from "images/blog/Screenshot.webp"
import imageTwo from "images/blog/hookdump3.webp"
import imageThree from "images/blog/hookdump5.webp"

export default ({}) => {
  useEffect(()=>
  {
    ReactGA.send({ hitType: "pageview", page:"/blog/helloworld/"});
  });
  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} links={navLinks}/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading className="blog-heading"><span style={{color: '#ff4c00' }}>Hook</span><span style={{color: '#000000' }}>Dump</span></Heading>
          </HeadingRow>
          <Subheading>Full-spectrum EDR hook detection with low false positives</Subheading>
          <CreationDate>March 30, 2021</CreationDate>
          <br/>
          <div>
          One of our side projects recently required us to generate a list of functions being hooked by an EDR with the following requirements:
          </div>
          <br/>
          <div>
            <ul>
              <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Low false positive rate</li>
              <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Check a list of DLL’s not just NTDLL</li>
              <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Locate hooks in the second or third instruction of a function</li>
              <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>Detect WOW syscall stub tampering</li>
              <li style={{listStylePosition: "inside", listStyleType: "circle", display : "list-item"}}>No ASCII art</li>
            </ul>
          </div>
          <br/>
          <div>
          We did a quick Google to see if there was an existing tool that could give us an answer quickly and found that EDR hooking is something that has been generating a lot of noise recently on social media, some of it good and some we are unsure if it’s a joke; one blog we read showed a screenshot of redirected functions from kernel32 to kernelbase, with the claim they found some hooks!
          </div>
          <br/>
          <div>
          In the end we decided to spend a few hours putting together our own tool; the imaginatively titled <span style={{fontWeight : "bold"}}>HookDump</span>
          </div>
          <br/>
          <center>
            <Img src={imageOne} alt="screenshot"></Img>
          </center>
          <br/>
          <Subheading>The details</Subheading>
          <br/>
          <div>
          Naive hook scanning solutions will simply scan a loaded DLL for <span style={{fontWeight : "bold"}}>jmp</span> instructions in the first byte of a function, leading to many false positives, for example exported variables etc.
          </div>
          <br/>
          <center>
            <Img src={imageTwo} alt="screenshot"></Img>
          </center>
          <br/>
          <div>
          HookDump has a low (zero?) false-positive rate, this is achieved by loading each DLL examined twice, once using LoadLibrary and secondly by reading from disk into a buffer. Exported functions are resolved in both loaded copies, several instructions are disassembled from both copies and then the instructions are compared. Using this method HookDump can detect hooks in the first second or third instruction of a function.
          </div>
          <br/>
          <div>
          We have observed some EDR’s creating a fake entry for NTDLL in the PEB module list, combined with page guards/no-execute memory access permissions and exception handlers, trusted execution flow is redirected to the real NTDLL in an attempt to thwart shellcode manually locating function addresses by parsing the exports table. This is an old technique going back to 2005; Piotr Bania first detailed it in <Link href="http://phrack.org/issues/63/15.html#article">Phrack magazine #63</Link>. HookDump is able to locate the original copy of NTDLL and examine it for hooks.
          </div>
          <br/>
          <div>
          HookDump also examines multiple DLL’s instead of just looking at NTDLL, the list of libraries is stored in the source file <Link href="https://github.com/zeroperil/HookDump/blob/main/LibraryList.inl">LibraryList.inl</Link> and was created by dumping a list of DLL’s loaded in explorer.exe using <Link href="https://docs.microsoft.com/en-us/sysinternals/downloads/process-explorer">SysInternals ProcessExplorer.</Link>
          </div>
          <br/>
          <center>
            <Img src={imageThree} alt="screenshot"></Img>
          </center>
          <br/>
          <Subheading>Update version 1.1</Subheading>
          <br/>
          <div>
          Some security products also modify the WOW64 syscall stub in 32 bit executables, HookDump now also detects this. Using this method, there is usually no need for direct jmp hooks in NTDLL so you may find that the tool only detects WOW64 syscall modification and no other hooks when running the 32 bit version. As with any tools like this, if you are unsure of the output, proper verification can be achieved by attaching a debugger and locating the hooks manually.
          </div>
          <br/>
          <Subheading>Source Code</Subheading>
          <br/>
          <div>
          Source code is available in the <Link href="https://github.com/zeroperil">Zeroperil GitHub</Link> repository. Obviously, this hasn’t been tested against every single security product. If you find a bug, please feel free to fix it and send us a pull request 🙂
          </div>
          <br/>
          <br/>
          <PrimaryLink href="/blog">
            Blog Index
          </PrimaryLink>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

