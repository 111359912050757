import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";

import imageOne from "images/aboutus/cyber-security.webp"

import imageTom from "images/aboutus/tom.webp"
import imageKye from "images/aboutus/kye.webp"
import ReactGA from "react-ga4";

// ---------------------------------------------------------
//
//
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import logo from "images/logo-light.svg";
const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);

const cards = [
  {
    imageSrc: `url('${imageTom}')`,
    position: "Co-Founder",
    name: "Tom Wilson",
    links: [
      {
        url: "https://twitter.com/@uint_ptr",
        icon: TwitterIcon,
      },
      {
        url: "https://www.linkedin.com/in/tom-wilson-cyber/",
        icon: LinkedinIcon,
      },
      {
        url: "https://github.com/Coder666",
        icon: GithubIcon,
      },
    ],
  },
  {
    imageSrc: `url('${imageKye}')`,
    position: "Co-Founder",
    name: "Kyriakos Economou",
    links: [
      {
        url: "https://twitter.com/kyREcon",
        icon: TwitterIcon,
      },
      {
        url: "https://www.linkedin.com/in/kyriakos-economou-ab298132/",
        icon: LinkedinIcon,
      },
      {
        url: "https://github.com/kyREcon",
        icon: GithubIcon,
      },
    ],
  }
];




const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page:'/about'});
  });

  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink}/>

      <TeamCardGrid 
        heading="Who Are We?"
        description = ""
        cards={cards}
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
