import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/zeroperil.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/zeroperil.svg";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header, {LogoLink, PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import logo from "images/logo-light.svg";
import threatErazerProductImage from "images/threaterazer/logo.webp"
import trainingProductImage from "images/blog/training.webp"
import hookDumpProductImage from "images/blog/hookdump3.webp"


const logoLink = (
  <LogoLink href="/" id="header-logo">
    <img src={logo} alt="logo" />
    <span style={{color: '#ff4c00' }}>ZERO</span><span style={{color: '#000000' }}>PERIL</span>
  </LogoLink>
);
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.div`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-900 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.div`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-32 h-32 transform translate-x-64 -translate-y-full opacity-25 text-primary-900`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-1/2 left-0 w-64 h-64 transform -translate-x-32 translate-y-full opacity-10 text-primary-800`;

export default ({
  heading = "Products",
  subheading = null,
  description = null,

}) => {
  const products = [
    {
      imageSrc:
        `url('${threatErazerProductImage}')`,
      title: "ThreatErazer Lite",
      description: "Free for non-commercial use endpoint hardening and malware detection platform with detailed logging capabilities",
      url: "/threaterazer/"
    },
    {
      imageSrc:
        `url('${trainingProductImage}')`,
      title: "Advanced Malware Unpacking",
      description: "Training course, taking a deep dive into executable packers and malware unpacking.",
      url: "/blog/malware-unpacking-training/"
    },
    {
      imageSrc:
        `url('${hookDumpProductImage}')`,
      title: "HookDump",
      description: "Free EDR hook information tool, including source code on github",
      url: "https://github.com/zeroperil/HookDump"
    }
  ];
  return (
    <AnimationRevealPage>
      <Header logoLink={logoLink} />
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          {description && <HeadingDescription>{description}</HeadingDescription>}
        </HeadingInfoContainer>
        <ThreeColumn>
          {products.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image style={{backgroundImage: `${post.imageSrc}` }} />
                <Details>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  <Link href={post.url}>More Info ...</Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
        <DecoratorBlob1/>
        <DecoratorBlob2/>
      </Content>
      <Footer />
    </AnimationRevealPage>
  );
};
